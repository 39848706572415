import { defineStore } from 'pinia'
// models
import type { userPreferencesState } from '~/store/userPreferences/models/userPreferencesState'

const state = () => ({
  selectedTheme: 'dark',
  selectedLanguage: 'en',
})

const actions = {
  setSelectedTheme(theme: string) {
    // @ts-ignore
    this.selectedTheme = theme
  },
  setSelectedLanguage(language: string) {
    // @ts-ignore
    this.selectedLanguage = language
  },
}

const getters = {
  getSelectedTheme: (state: userPreferencesState) => {
    return state.selectedTheme
  },
  getSelectedLanguage: (state: userPreferencesState) => {
    return state.selectedLanguage
  },
}
// @ts-ignore
export const useUserPreferences = defineStore('userPreferences', {
  state,
  actions,
  getters,
  persist: {
    storage: persistedState.localStorage,
  },
})
